import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Tag } from "../atoms/Tag";

type Props = {
  tags: (string | undefined)[];
};

const TagContainer = styled.div`
  & + div {
    margin-left: 15px;
  }
`;

export const TagList: React.FC<Props> = props => {
  const tags = props.tags;

  return (
    <ul css={listStyle}>
      {tags.map((tag: any, index: number) => (
        <TagContainer key={index}>
          <Tag>{tag}</Tag>
        </TagContainer>
      ))}
    </ul>
  );
};

const listStyle = css({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  listStyle: "none",
});
