import React from "react";
import { Link, graphql } from "gatsby";

import { css } from "@emotion/react";
import { Layout } from "../components/layout";
import { SEO } from "../components/seo";
import { TagList } from "../components/molecules/TagList";
import type { BlogPostBySlugQuery } from "../../types/graphql-types";

type Props = {
  data: BlogPostBySlugQuery;
  location: Location;
};

const BlogPostTemplate: React.FC<Props> = ({ data, location }) => {
  const post = data.markdownRemark;
  const { previous, next } = data;
  const tags: (string | undefined)[] | undefined = post?.frontmatter?.tags;

  return (
    <Layout location={location}>
      <SEO
        title={post?.frontmatter?.title}
        description={post?.frontmatter?.description || post?.excerpt}
      />
      <article className="blog-post" itemScope itemType="http://schema.org/Article">
        <header>
          <h1 itemProp="headline">{post?.frontmatter?.title}</h1>
          <p css={{ marginBottom: "1rem" }}>update：{post?.frontmatter?.date}</p>
          {tags && <TagList tags={tags}></TagList>}
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post?.html || "" }}
          itemProp="articleBody"
          css={articleBody}
        />
        <hr />
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous?.fields?.slug && previous.frontmatter && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next?.fields?.slug && next.frontmatter && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

const articleBody = css({
  backgroundColor: "#fff",
});
