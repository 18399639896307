import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";

type Props = {
  children: string;
};

export const Tag: React.FC<Props> = props => {
  const { children } = props;

  return (
    <Link to={`/tags/${children}`}>
      <li css={tag}>{children}</li>
    </Link>
  );
};

const tag = css({
  borderRadius: "6px",
  backgroundColor: "#fff",
  border: "1px solid #ddd",
  padding: "0.2rem 0.4rem",
  color: "#000",
  letterSpacing: "0.04rem",
});
